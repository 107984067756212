import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppConsts from "../../appconst";
import axiosInstance from "../../auth/AxiosInstance";

export const applyLoyaltyByEarn = createAsyncThunk(
  "applyLoyaltyByEarn",
  async (totalAmount, thunkApi) => {
    try {
      const response = await axiosInstance.get(
        `${AppConsts.apiBaseUrl}/ApplyLoyalty/GetLoyaltyEarn/${totalAmount}`
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const applyLoyaltyByRedeem = createAsyncThunk(
  "applyLoyaltyByRedeem",
  async (totalAmount, thunkApi) => {
    try {
      const response = await axiosInstance.get(
        `${AppConsts.apiBaseUrl}/ApplyLoyalty/GetLoyaltyRedeem/${totalAmount}`
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

const loyaltySlice = createSlice({
  name: "Loyalty",
  initialState: {
    loyaltyDetails: null,
  },
  reducers: {
    resetLoyaltyState(state, action) {
      state.loyaltyDetails = null;
    },
  },
  extraReducers: {
    [applyLoyaltyByEarn.pending]: (state, action) => {
      state.loading = true;
    },
    [applyLoyaltyByEarn.fulfilled]: (state, action) => {
      state.loading = false;
      state.loyaltyDetails = action.payload;
    },
    [applyLoyaltyByRedeem.pending]: (state, action) => {
      state.loading = true;
    },
    [applyLoyaltyByRedeem.fulfilled]: (state, action) => {
      state.loading = false;
      state.loyaltyDetails = action.payload;
    },
  },
});

export const thunks = {
  applyLoyaltyByEarn,
  applyLoyaltyByRedeem,
};
export const { resetLoyaltyState } = loyaltySlice.actions;

export default loyaltySlice.reducer;
